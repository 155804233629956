import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { connect } from "react-redux";
import { setRoom } from "../redux/actionCreators";

import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Content,
  Menu,
  RoomButton,
  RoomMini,
  VoiceOver,
  RoomOverlay
} from "../components";

import * as roomImage from "../assets/rooms";
import * as roomPreviewImage from "../assets/rooms_preview";
import scrollLeft from "../assets/arrow-left.svg";
import scrollRight from "../assets/arrow-right.svg";

import { rooms } from "../data";
import Analytics from "../lib/Analytics";

const EMBASSY_ZOOM_DURATION = 1500;
const ROOM_FADE_DURATION = 500;
const SCROLL_DURATION = 1000;

class Embassy extends React.Component {
  Tracker = new Analytics();
  animationStartTime = 0;

  static propTypes = {
    guide: PropTypes.object
  };

  state = {
    previewRoom: undefined,
    previewEmbassy: this.props.showPreviewAnimation,
    showUIControls: !this.props.showPreviewAnimation,
    scrolledRight: false
  };

  constructor(props) {
    super(props);
    this.articleRef = React.createRef();
    this.imageRef = React.createRef();
    this.timer = null;
  }

  componentDidMount() {
    const { currentGuide, currentRoom } = this.props;
    if (currentRoom) {
      this.Tracker.pageView(
        `/embassy/${currentGuide.id}/${currentRoom.id}`,
        currentRoom.title
      );
    } else {
      this.Tracker.pageView(`/embassy/${currentGuide.id}`, "Exterior");
    }
    // Preload images
    rooms.forEach(room => {
      let imgPreview = new Image();
      imgPreview.src = roomPreviewImage[room.id];
    });

    rooms.forEach(room => {
      let img = new Image();
      img.src = roomImage[room.id];
    });

    if (this.props.showPreviewAnimation) {
      this.timer = setTimeout(() => {
        this.setState({ previewEmbassy: false });
        setTimeout(() => {
          this.setState({ showUIControls: true });
        }, EMBASSY_ZOOM_DURATION);
      }, 1500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  setPreviewRoom = room => {
    this.setState({ previewRoom: room });
  };

  onRoomButtonClicked = (room, setRoom) => {
    setRoom(room);

    setTimeout(() => {
      this.setState({ previewRoom: undefined });
    }, ROOM_FADE_DURATION);
  };

  renderScrollUpdate = currentTime => {
    if (this.animationStartTime === 0) {
      this.animationStartTime = currentTime;
    }

    const progress = (currentTime - this.animationStartTime) / SCROLL_DURATION;
    if (progress > 1) {
      this.animationStartTime = 0;
      return;
    }

    const imageWidth = this.imageRef.current.width;
    const viewportWidth = window.innerWidth;
    const scrollAmount = imageWidth - viewportWidth;

    let startValue;
    let endValue;
    if (this.state.scrolledRight) {
      startValue = 0;
      endValue = scrollAmount;
    } else {
      startValue = scrollAmount;
      endValue = 0;
    }

    const currentScrollOffset = startValue + progress * (endValue - startValue);

    window.scrollTo(currentScrollOffset, 0);

    window.requestAnimationFrame(this.renderScrollUpdate);
  };

  toggleScrolledRight = () => {
    window.requestAnimationFrame(this.renderScrollUpdate);

    this.setState({
      scrolledRight: !this.state.scrolledRight
    });
  };

  handleHoverOrClick = room => {
    let { showUIControls } = this.state;
    if (showUIControls) {
      this.setState({ previewRoom: room });
    }
  };

  render() {
    let {
      previewRoom,
      showUIControls,
      previewEmbassy,
      scrolledRight
    } = this.state;
    let { currentGuide, currentRoom, setRoom } = this.props;

    return (
      <React.Fragment>
        {showUIControls && (
          <button
            className={[
              "Embassy__scrollButton",
              this.state.scrolledRight
                ? "Embassy__scrollButton__left"
                : "Embassy__scrollButton__right"
            ].join(" ")}
            onClick={this.toggleScrolledRight}
          >
            {this.state.scrolledRight ? (
              <img src={scrollLeft} alt="Scroll left" />
            ) : (
              <img src={scrollRight} alt="Scroll right" />
            )}
          </button>
        )}

        <article
          className="Embassy"
          ref={this.articleRef}
          style={{ overflow: currentRoom ? "hidden" : "auto" }}
          id="Embassy__image"
        >
          {!currentRoom && showUIControls && (
            <React.Fragment>
              <VoiceOver embassyLevel={true}>
                <p>
                  Here it is! One of the embassies where we work. You might like
                  to start at the perimeter - that's the highlighted icon - or
                  you can tap or roll over any point to see the room inside,
                  then tap the thumbnail to go there.
                </p>
              </VoiceOver>
              <Menu embassyLevel={true} />
            </React.Fragment>
          )}

          <Content
            className={classNames([
              "Embassy__content",
              previewEmbassy
                ? "Embassy__content__preview"
                : "Embassy__content__full"
            ])}
          >
            <img
              src={"/assets/embassy-exterior.jpg"}
              ref={this.imageRef}
              alt="Embassy Exterior"
              className="Embassy__content__scene"
            />
            <ReactCSSTransitionGroup
              component={React.Fragment}
              transitionName="fade"
              transitionAppear={true}
              transitionEnter={false}
              transitionLeave={false}
              transitionAppearTimeout={EMBASSY_ZOOM_DURATION}
            >
              {!currentRoom &&
                rooms.map(room => (
                  <RoomButton
                    key={"RoomButton-" + room.id}
                    className="RoomButton__embassy-specific"
                    room={room}
                    guide={currentGuide}
                    ariaLabel={room.title}
                    pulsating={room.id === "perimeter" && showUIControls}
                    disabled={!!previewRoom}
                    onMouseOver={() => this.handleHoverOrClick(room)}
                    onClick={() => this.handleHoverOrClick(room)}
                  />
                ))}
              {previewRoom && (
                <RoomMini
                  key={previewRoom.id}
                  room={previewRoom}
                  guide={currentGuide}
                  onImageZoomStart={() =>
                    this.setState({ showUIControls: false })
                  }
                  onImageZoomComplete={() =>
                    this.onRoomButtonClicked(previewRoom, setRoom)
                  }
                  onClose={() => this.setPreviewRoom()}
                  articleRef={this.imageRef}
                  scrolledRight={scrolledRight}
                />
              )}
            </ReactCSSTransitionGroup>
          </Content>

          <ReactCSSTransitionGroup
            component={React.Fragment}
            transitionName="fade"
            transitionAppear={false}
            transitionEnterTimeout={ROOM_FADE_DURATION}
            transitionLeaveTimeout={ROOM_FADE_DURATION}
          >
            {currentRoom && (
              <RoomOverlay
                currentRoom={currentRoom}
                showUIControls={() => this.setState({ showUIControls: true })}
              />
            )}
          </ReactCSSTransitionGroup>
        </article>
      </React.Fragment>
    );
  }
}

const mapStateToProps = storeState => ({
  currentGuide: storeState.currentGuide,
  currentRoom: storeState.currentRoom,
  roomsHistory: storeState.roomsHistory
});

const mapDispatchToProps = {
  setRoom
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Embassy);
