import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { connect } from "react-redux";
import { setGuide, resetRoomsHistory } from "../redux/actionCreators";

import { Button, Carousel, Content, GuideSummary } from "../components";

import { guides } from "../data";
import Analytics from "../lib/Analytics";

Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

function centerPreviousGuide(previousGuide, newPosition) {
  let previousGuideIndex = previousGuide
    ? guides.indexOf(previousGuide)
    : undefined;
  if (previousGuideIndex) guides.move(previousGuideIndex, newPosition);
  return guides;
}

class GuidePicker extends React.Component {
  state = {
    guides: []
  };

  Tracker = new Analytics();

  componentDidMount() {
    this.Tracker.pageView("/embassy/start", "Pick a Guide");
    // Preload images before assigning state
    var guidesLoaded = 0;
    guides.forEach(guide => {
      var img = new Image();
      img.onload = () => {
        if (++guidesLoaded === guides.length) {
          this.setState({ guides: guides });
        }
      };
      img.src = guide.image;
      img.alt = guide.title;
    });
  }

  render() {
    const { guides } = this.state;
    const { setGuide, previousGuide } = this.props;
    return (
      <div className="Start__pick">
        <Content>
          <h1>Choose your guide</h1>
          <p>
            As you move around our virtual embassy, your guide will tell you
            more about their team’s role. You’ll get a snapshot of the work they
            do on government buildings, embassies and secure sites worldwide.
            And you’ll learn facts about FCDO Services along the way. So, who
            will your guide be?
          </p>
        </Content>
        <ReactCSSTransitionGroup
          transitionName="slide-up"
          transitionEnter={true}
          transitionLeave={false}
          transitionAppear={false}
          transitionEnterTimeout={500}
        >
          {guides.length && (
            <Carousel>
              {centerPreviousGuide(previousGuide, 2).map((guide, index) => (
                <GuideSummary
                  isCurrentGuide={guide === previousGuide}
                  key={"guide-" + index}
                  guide={guide}
                  previousGuide={previousGuide}
                  onClick={() => setGuide(guide)}
                />
              ))}
            </Carousel>
          )}
        </ReactCSSTransitionGroup>
        {previousGuide && (
          <Button
            onClick={() => {
              setGuide(previousGuide);
              this.props.history.push("/embassy");
            }}
            className={`Color__${
              previousGuide.id
            }--background KeepGuide__Button`}
          >
            Continue with current guide
          </Button>
        )}
      </div>
    );
  }
}

function GuideInfo({
  history,
  currentGuide,
  setGuide,
  currentRoom,
  resetRoomsHistory
}) {
  return (
    <div className="Start__meet">
      <GuideSummary
        guide={currentGuide}
        extraClasses={`GuideSummary__${currentGuide.id} Color__${
          currentGuide.id
        }`}
      />
      <Content>
        <h1>Meet your guide</h1>
        <div dangerouslySetInnerHTML={{ __html: currentGuide.detail }} />
        <div className="spaced-out-content spaced-out-content__buttons">
          <Button
            onClick={() => setGuide(null)}
            className={`Color__${currentGuide.id}--background`}
          >
            Back
          </Button>
          <Button
            className={`Color__${currentGuide.id}--background`}
            onClick={() => history.push("/embassy")}
          >{`${currentRoom ? "Switch & resume" : "Continue"}`}</Button>
          {currentRoom && (
            <Button
              className={`Color__${currentGuide.id}--background`}
              onClick={() => {
                history.push("/embassy");
                resetRoomsHistory();
              }}
            >
              Switch & restart
            </Button>
          )}
        </div>
      </Content>
    </div>
  );
}

const mapStateToProps = storeState => ({
  currentGuide: storeState.currentGuide,
  previousGuide: storeState.previousGuide,
  currentRoom: storeState.currentRoom
});

const mapDispatchToProps = {
  setGuide,
  resetRoomsHistory
};

function Start(props) {
  return (
    <article className="Start">
      {props.currentGuide ? (
        <GuideInfo {...props} />
      ) : (
        <GuidePicker {...props} />
      )}
    </article>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Start);
