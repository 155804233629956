import React from "react";

import { Content } from "../";
import { closeIconWhite } from "../../assets";

import { connect } from "react-redux";
import { hideContent } from "../../redux/actionCreators";
import { onDocumentClick } from "../../lib/HelperFunctions";

class HotspotContent extends React.Component {
  onDocumentClickHotspot = e => {
    let { hideContent } = this.props;
    onDocumentClick(e, ["HotspotContent__Container"], () => {
      hideContent();
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.onDocumentClickHotspot);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClickHotspot);
  }

  render() {
    let { children, hideContent, cultural } = this.props;
    return (
      <Content
        className={`HotspotContent ${
          cultural ? "HotspotContent__Cultural" : ""
        }`}
      >
        <button className="VoiceOver__close" onClick={hideContent}>
          <img src={closeIconWhite} alt="Close pop-up" />
        </button>
        {children}
      </Content>
    );
  }
}

const mapDispatchToProps = {
  hideContent
};

export default connect(
  null,
  mapDispatchToProps
)(HotspotContent);
