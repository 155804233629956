import React from "react";

import find from "lodash/find";

import { connect } from "react-redux";
import { setRoom } from "../../redux/actionCreators";
import { FadeOutOverlay } from "../";
import { onDocumentClick } from "../../lib/HelperFunctions";

import { ReactComponent as HistoryMapLarge } from "../../assets/map_large.svg";
import { closeIconWhite } from "../../assets";

import { rooms } from "../../data";

class RoomsHistoryMap extends React.Component {
  onDocumentClickMap = e => {
    let { hideRoomsHistoryMap } = this.props;
    onDocumentClick(e, ["RoomsHistoryMap"], () => {
      hideRoomsHistoryMap();
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.onDocumentClickMap);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClickMap);
  }

  handleClick = e => {
    if (!e.target.parentNode) {
      return;
    }
    let { hideRoomsHistoryMap, setRoom } = this.props;
    let roomId =
      e.target.nodeName === "g" ? e.target.id : e.target.parentNode.id;
    let room = find(rooms, ["id", roomId]);
    if (room) {
      setRoom(room);
      hideRoomsHistoryMap();
    }
  };

  render() {
    let { hideRoomsHistoryMap, previousRoomsClass, currentRoom } = this.props;
    return (
      <React.Fragment>
        <FadeOutOverlay />
        <div className="RoomsHistoryMap">
          <div className="RoomsHistoryMapLargeContainer">
            <HistoryMapLarge
              className={`HistoryMap--${currentRoom.id} ${previousRoomsClass}`}
              onClick={this.handleClick}
            />
          </div>
          <div className="RoomsHistoryMap--intro Content">
            <p>
              This is the mini-map - a cross-section of the embassy. It shows
              where you’ve been so far, and you can click any room to go there.
            </p>
          </div>
          <button
            className="RoomsHistoryMap__close"
            onClick={hideRoomsHistoryMap}
          >
            <img src={closeIconWhite} alt="Close map view" />
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = storeState => ({
  currentRoom: storeState.currentRoom,
  roomsHistory: storeState.roomsHistory
});

const mapDispatchToProps = {
  setRoom
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomsHistoryMap);
