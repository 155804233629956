const hasClass = (element, className) => {
  return (
    element.className &&
    element.className.indexOf &&
    element.className.indexOf(className) >= 0
  );
};

const hasAnyClass = (node, classes) => {
  let hasAtLeastAClass = false;

  classes.map(currentClass => {
    if (hasClass(node, currentClass)) {
      hasAtLeastAClass = true;
    }
  });

  return hasAtLeastAClass;
};

export const onDocumentClick = (e, classes, callback) => {
  let shouldHideElement = true;
  let node = e.target;
  while (node) {
    if (hasAnyClass(node, classes)) {
      shouldHideElement = false;
      break;
    }

    node = node.parentNode;
  }

  if (shouldHideElement) {
    callback();
  }
};
