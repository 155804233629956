import React from "react";
import { ReactComponent as FocusPlus } from "../../assets/focus-plus.svg";

export default function RoomButton({
  room,
  guide,
  disabled,
  pulsating,
  onMouseOver,
  onClick,
  className,
  ariaLabel
}) {
  return (
    <button
      className={`RoomButton ${className} ${
        pulsating ? "RoomButton-pulsating" : ""
      } Color__${guide.id}--fill RoomButton-${room.id}`}
      aria-label={ariaLabel}
      disabled={disabled}
      onMouseOver={onMouseOver}
      onClick={onClick}
    >
      <FocusPlus />
    </button>
  );
}
