import filter from "lodash/filter";

import {
  SET_GUIDE,
  SET_ROOM,
  SHOW_EMBASSY,
  RESET_ROOMS_HISTORY,
  SHOW_CONTENT,
  HIDE_CONTENT,
  SET_HOTSPOT,
  SHOW_HELP,
  HIDE_HELP
} from "./actionTypes";
import Analytics from "../lib/Analytics";
const Tracker = new Analytics();

const getContentType = (roomsHistory, currentHotspot) => {
  if (currentHotspot) {
    return "hotspot_content";
  } else if ((roomsHistory || []).length > 0) {
    return "subsequent_room";
  } else {
    return "first_room";
  }
};

export default (state, action) => {
  switch (action.type) {
    case SET_GUIDE: {
      const guide = action.payload;
      if (guide === null) {
        Tracker.event("Guide", "reset");
      } else {
        if (state.currentGuide !== undefined) {
          Tracker.event("Guide", "change", guide.id);
        } else {
          Tracker.event("Guide", "set", guide.id);
        }
      }
      return {
        ...state,
        currentGuide: guide,
        previousGuide: state.currentGuide
      };
    }

    case SET_ROOM: {
      const room = action.payload;
      Tracker.pageView(
        `/embassy/${state.currentGuide.id}/${room.id}`,
        room.title
      );
      return {
        ...state,
        currentRoom: room,
        contentVisible: true,
        currentHotspot: undefined,
        roomsHistory: [...state.roomsHistory, room.id],
        typeOfContent: getContentType(state.roomsHistory, state.currentHotspot),
        roomsToHighlight: filter(
          state.roomsHistory,
          previousRoom => previousRoom !== room.id
        )
      };
    }

    case SHOW_EMBASSY: {
      Tracker.pageView(`/embassy/${state.currentGuide.id}`, "Exterior");
      return {
        ...state,
        currentRoom: undefined,
        contentVisible: false
      };
    }

    case SHOW_CONTENT: {
      return {
        ...state,
        contentVisible: true
      };
    }

    case HIDE_CONTENT: {
      return {
        ...state,
        currentHotspot: undefined,
        contentVisible: false,
        typeOfContent: getContentType(state.roomsHistory, undefined)
      };
    }

    case SET_HOTSPOT: {
      const hotspot = action.payload;
      const { currentRoom, currentGuide } = state;
      Tracker.pageView(
        `/embassy/${currentGuide.id}/${currentRoom.id}/${hotspot.id}`,
        `${hotspot.name || hotspot.id} - ${currentRoom.title}`
      );
      return {
        ...state,
        currentHotspot: hotspot,
        contentVisible: true,
        typeOfContent: getContentType(state.roomsHistory, hotspot)
      };
    }

    case RESET_ROOMS_HISTORY: {
      Tracker.event("App", "restart", state.currentGuide.id);
      return {
        ...state,
        currentRoom: undefined,
        contentVisible: false,
        roomsHistory: []
      };
    }

    case SHOW_HELP: {
      return {
        ...state,
        currentHelp: action.payload,
        showHelp: true
      };
    }

    case HIDE_HELP: {
      return {
        ...state,
        showHelp: false
      };
    }

    default:
      return state;
  }
};
