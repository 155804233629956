import React from "react";

import { HotspotInformationNav } from "../";
import { ReactComponent as HotspotIcon } from "../../assets/hotspot.svg";
import { addNonBreakingSpace } from "../../App";

class HotspotInformation extends React.Component {
  state = {
    activeTab: "overview-tab",
    currentSlide: 0
  };

  toggleHappeningTab = tab => {
    this.setState({ activeTab: tab });
  };

  previousSlide = () => {
    this.setState({
      currentSlide: this.state.currentSlide - 1
    });
  };

  nextSlide = () => {
    this.setState({
      currentSlide: this.state.currentSlide + 1
    });
  };

  pickSlide = slideNumber => {
    this.setState({
      currentSlide: slideNumber
    });
  };

  render() {
    let {
      intro,
      title,
      slides,
      involved,
      currentGuide,
      cultural,
      currentHotspotId
    } = this.props;
    let { activeTab, currentSlide } = this.state;

    return (
      <div className="HotspotContent__Container">
        {cultural ? (
          <React.Fragment>
            <div className="HotspotContent__Cultural--Icon Color__cultural--background-no-hover">
              <HotspotIcon />
            </div>
            <div className="HotspotContent__Cultural--Slide">
              <div className="HotspotContent__Title">
                <p className="Color__cultural">Did you know?</p>
              </div>
              <div>
                {slides[currentSlide].map((paragraph, index) => (
                  <p className="Hotspot__paragraph" key={`paragraph-${index}`}>
                    {addNonBreakingSpace(paragraph)}
                  </p>
                ))}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="Tabs">
              <button
                className={`Overview__tab ${
                  activeTab === "overview-tab" ? "Overview__tab--active" : ""
                } `}
                onClick={() => this.toggleHappeningTab("overview-tab")}
              >
                <p>Overview</p>
              </button>
              <button
                className={`Happening__tab ${
                  activeTab === "happening-tab" ? "Happening__tab--active" : ""
                } `}
                onClick={() => this.toggleHappeningTab("happening-tab")}
              >
                <p>What's happening</p>
              </button>
              {involved && (
                <button
                  className={`Involved__tab ${
                    activeTab === "involved-tab" ? "Involved__tab--active" : ""
                  }`}
                  onClick={() => this.toggleHappeningTab("involved-tab")}
                >
                  <p>Who's involved</p>
                </button>
              )}
            </div>

            {activeTab !== "overview-tab" && (
              <div className="HotspotContent__Slides__Container">
                <div
                  className={`HotspotContent__Title Color__${currentGuide.id}`}
                >
                  <p className="Hotspot__title">{title}</p>
                </div>

                {activeTab === "happening-tab" && (
                  <React.Fragment>
                    <div className="HotspotContent__Paragraphs">
                      {slides[currentSlide].map((paragraph, index) => (
                        <p
                          className="Hotspot__paragraph"
                          key={`paragraph-${index}`}
                        >
                          {addNonBreakingSpace(paragraph)}
                        </p>
                      ))}
                    </div>
                    <HotspotInformationNav
                      totalSlides={slides.length}
                      onNextClick={this.nextSlide}
                      onPrevClick={this.previousSlide}
                      onDotClick={this.pickSlide}
                      currentSlide={currentSlide}
                    />
                  </React.Fragment>
                )}

                {activeTab === "involved-tab" && (
                  <ul
                    className={`Involved__${
                      currentGuide.id
                    }__${currentHotspotId}`}
                  >
                    {involved.map((involvedText, index) => (
                      <li key={`list-item-${index}`}>{involvedText}</li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            {activeTab === "overview-tab" && (
              <div
                className={`HotspotContent__Overview Color__${currentGuide.id}`}
              >
                <h1 className="Hotspot__intro">{addNonBreakingSpace(intro)}</h1>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default HotspotInformation;
