import React from "react";
import { Button } from "../";
import * as roomImage from "../../assets/rooms_preview";
import { ReactComponent as CloseIcon } from "../../assets/focus-plus.svg";
import classNames from "classnames";

export default class RoomMini extends React.Component {
  state = {
    expanding: false,
    clickedOutside: false
  };

  constructor(props) {
    super(props);
    this.imgRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  handleClick = e => {
    if (this.imgRef.current && !this.imgRef.current.contains(e.target)) {
      this.setState({
        clickedOutside: true
      });
      this.props.onClose();
      return;
    }
  };

  onEnterClicked = () => {
    this.setState({
      expanding: true
    });

    this.props.onImageZoomStart();

    const img = this.imgRef.current;
    const rect = img.getBoundingClientRect();
    const {
      left: scrollLeft,
      top: scrollTop
    } = this.props.articleRef.current.getBoundingClientRect();
    const imageAspectRatio = rect.width / rect.height;

    let targetWidth = window.innerWidth - 2 * window.innerWidth * 0.15;
    let targetHeight = targetWidth / imageAspectRatio;

    const targetTop = (window.innerHeight - targetHeight) / 2 - scrollTop;
    const targetLeft = (window.innerWidth - targetWidth) / 2 - scrollLeft;

    img.style.left = Math.round(targetLeft) + "px";
    img.style.top = Math.round(targetTop) + "px";
    img.style.width = Math.round(targetWidth) + "px";
    img.style.height = Math.round(targetHeight) + "px";

    setTimeout(() => {
      this.props.onImageZoomComplete();
    }, 1000);
  };

  render() {
    const { room, guide, onClose, scrolledRight } = this.props;
    const { clickedOutside, expanding } = this.state;

    return (
      <React.Fragment>
        <div
          className={classNames([
            `RoomMini__background`,
            expanding
              ? "RoomMini__background__visible"
              : "RoomMini__background__invisible"
          ])}
        />

        {!clickedOutside && (
          <div className={`RoomMini RoomMini-${room.id}`} ref={this.imgRef}>
            <img src={roomImage[room.id]} alt={room.title} />

            {!expanding && (
              <div
                className={`RoomMini-details ${
                  !scrolledRight
                    ? "RoomMini-details__text-left"
                    : "RoomMini-details__text-right"
                } Color__${guide.id}--background-no-hover Color__${
                  guide.id
                }--outline`}
              >
                <p>{room.title}</p>
                <Button
                  className={`Button--white  Color__${guide.id}`}
                  onClick={this.onEnterClicked}
                >
                  Enter
                </Button>
                <Button
                  className={`RoomButton RoomMini__Close Color__${
                    guide.id
                  }--fill`}
                  key={"RoomPreview-" + room.id}
                  onClick={onClose}
                >
                  <CloseIcon title="Close room preview" />
                </Button>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
