export default class Analytics {
  GA_MEASUREMENT_ID = process.env.NODE_ENV === 'production' ? 'GTM-5KCLW7R' : 'GTM-AAAAAAA'

  pageView(pagePath, pageTitle) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ 
      event: 'page_view',
      page_path: pagePath,
      page_title: pageTitle 
    });
  }

  event(category, action, label) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: action,
      event_category: category,
      event_label: label,
    })
  }
}

