import React from "react";
import classNames from "classnames";

class GuideSummary extends React.Component {
  state = {
    guideHovered: false
  };

  hoverOn = () => {
    this.setState({ guideHovered: true });
  };

  hoverOff = () => {
    this.setState({ guideHovered: false });
  };

  render() {
    let {
      onClick,
      guide,
      previousGuide,
      selected,
      onKeepGuide,
      style,
      isCurrentGuide,
      extraClasses,
      ...props
    } = this.props;
    let { guideHovered } = this.state;
    const classes = classNames(
      "GuideSummary",
      { "GuideSummary--selected": selected },
      `${guideHovered && guide !== previousGuide ? "GuideSummary--hover" : ""}`,
      `${isCurrentGuide ? "GuideSummary__Current-Guide" : ""}`
    );
    return (
      <div
        className={classes}
        style={style}
        onClick={onClick}
        onTouchStart={onClick}
        onKeyDown={e => e.keyCode === 13 && onClick(e)}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
        tabIndex={onClick ? 0 : ""}
        aria-label={`Choose ${guide.title} guide`}
      >
        <img
          src={guide.image}
          alt={guide.title}
          className="GuideSummary__image"
        />
        <div className={`${extraClasses} GuideSummary__role`} {...props}>
          {guide.summary}
        </div>
      </div>
    );
  }
}

export default GuideSummary;
