import React from "react";

import { connect } from "react-redux";
import { setRoom, setHotspot, hideContent } from "../../redux/actionCreators";

import {
  Menu,
  HotspotButton,
  VoiceOver,
  RoomsHistory,
  RoomsHistoryMap,
  HotspotInformation,
  HotspotContent
} from "../";
import { addNonBreakingSpace } from "../../App";
import roomControlArrow from "../../assets/room-control-arrow-white.png";
import * as roomImage from "../../assets/rooms";

import { ReactComponent as HotspotIcon } from "../../assets/hotspot.svg";

import { rooms, hotspots, guide_pop_up } from "../../data";

import sustainability from "../../data/sustainability.json";
import constructing from "../../data/constructing.json";
import warehouse from "../../data/warehouse.json";
import projects from "../../data/projects.json";
import designing_buildings from "../../data/designing_buildings.json";
import designing_features from "../../data/designing_features.json";
import installing from "../../data/installing.json";
import apprentice from "../../data/apprentice.json";

let content = {
  sustainability: sustainability,
  constructing: constructing,
  warehouse: warehouse,
  projects: projects,
  designing_buildings: designing_buildings,
  designing_features: designing_features,
  installing: installing,
  apprentice: apprentice
};

class RoomOverlay extends React.Component {
  state = {
    showRoomHistoryMapLarge: false
  };

  getRoomByName = roomName => {
    return rooms.find(room => room.id === roomName);
  };

  getContent = (currentRoom, typeOfContent, currentGuide) => {
    if (typeOfContent === "subsequent_room") {
      return (
        <p>
          {addNonBreakingSpace(guide_pop_up[currentRoom.id]["subsequent_room"])}
        </p>
      );
    } else {
      
      if (currentGuide.id == "apprentice") {
        return (
          <div>
            <p>
              Welcome to our virtual embassy! Click{" "}
              <HotspotIcon
                className={`HotspotIcon-example Color__${currentGuide.id}--fill`}
              />{" "}
              to learn more about all kinds of apprenticeships here. There are hotspots about every programme we offer, from IT to Finance, so if one isn’t relevant to you, don’t be put off – it’s a chance to learn about the wider cohort you’ll be joining. You can also click{" "}
              <HotspotIcon className="HotspotIcon-example Color__cultural--fill" />{" "}
              for interesting facts relevant to everyone, about the whole of FCDO Services. Then, when you’re done, use the arrows to find a new area to explore, or use the side navigation for more help.
            </p>
            <p>{guide_pop_up[currentRoom.id]["first_room"]}</p>
          </div>
        );
      } else {
        return (
          <div>
            <p>
              Welcome to our virtual embassy! Click{" "}
              <HotspotIcon
                className={`HotspotIcon-example Color__${currentGuide.id}--fill`}
              />{" "}
              to learn more about my team’s work, or{" "}
                <HotspotIcon className="HotspotIcon-example Color__cultural--fill" />{" "}
              for interesting facts about the whole of FCDO Services. Then, when
              you’re done, use the arrows to find a new area to explore.
            </p>
            <p>
              You can also use the side navigation to find another room, change
              guides, or get help on how the embassy works.
            </p>
            <p>{guide_pop_up[currentRoom.id]["first_room"]}</p>
          </div>
        )
      }

    }
  };

  componentWillUnmount() {
    this.props.showUIControls();
  }

  showRoomsHistoryMap = () => {
    let { hideContent } = this.props;
    this.setState({ showRoomHistoryMapLarge: true });
    hideContent();
  };

  hideRoomsHistoryMap = () => {
    this.setState({ showRoomHistoryMapLarge: false });
  };

  render() {
    let {
      currentRoom,
      currentGuide,
      setRoom,
      roomsHistory,
      roomsToHighlight,
      setHotspot,
      currentHotspot,
      typeOfContent,
      contentVisible
    } = this.props;
    let { showRoomHistoryMapLarge } = this.state;
    let { left, right, top, bottom } = currentRoom.arrows;
    let currentHotspots = hotspots[currentRoom.id].filter(hotspot =>
      hotspot.guides.includes(currentGuide.id)
    );

    let previousRoomsClass = roomsToHighlight
      ? roomsToHighlight
          .map(previousRoom => `HistoryMap_visited--${previousRoom}`)
          .join(" ")
      : "";
    return (
      <div className="Embassy__Room__Overlay">
        <div className="Embassy__Room__Container-outer">
          <div className="Embassy__Room__Container-inner">
            <img
              src={roomImage[currentRoom.id]}
              className="Embassy__Room__Image"
              alt={currentRoom.title}
            />

            <button
              className="Embassy__Room__Controls Embassy__Room__Controls__Left"
              disabled={!left}
              value="Go to room on left side"
              onClick={() => setRoom(this.getRoomByName(left))}
            >
              <img
                src={roomControlArrow}
                alt="Move left"
                style={{ display: left ? "block" : "none" }}
              />
            </button>
            <button
              className="Embassy__Room__Controls Embassy__Room__Controls__Right"
              disabled={!right}
              value="Go to room on right side"
              onClick={() => setRoom(this.getRoomByName(right))}
            >
              <img
                src={roomControlArrow}
                alt="Move right"
                style={{ display: right ? "block" : "none" }}
              />
            </button>
            <button
              className="Embassy__Room__Controls Embassy__Room__Controls__Up"
              disabled={!top}
              value="Go to above room"
              onClick={() => setRoom(this.getRoomByName(top))}
            >
              <img
                src={roomControlArrow}
                alt="Move up"
                style={{ display: top ? "block" : "none" }}
              />
            </button>
            <button
              className="Embassy__Room__Controls Embassy__Room__Controls__Down"
              disabled={!bottom}
              value="Go to below room"
              onClick={() => setRoom(this.getRoomByName(bottom))}
            >
              <img
                src={roomControlArrow}
                alt="Move down"
                style={{ display: bottom ? "block" : "none" }}
              />
            </button>

            {currentHotspots.map(hotspot => (
              <HotspotButton
                guide={currentGuide}
                key={hotspot.id}
                hotspot={hotspot}
                style={{
                  top: `${hotspot.offset[1]}%`,
                  left: `${hotspot.offset[0]}%`
                }}
                onClick={() => setHotspot(hotspot)}
              />
            ))}

            <VoiceOver embassyLevel={false} typeOfContent={typeOfContent}>
              <div>
                {this.getContent(currentRoom, typeOfContent, currentGuide)}
              </div>
            </VoiceOver>

            {contentVisible && typeOfContent === "hotspot_content" && (
              <HotspotContent cultural={currentHotspot.id === "cultural"}>
                <HotspotInformation
                  {...content[currentGuide.id][currentRoom.id][
                    currentHotspot.id
                  ]}
                  currentGuide={currentGuide}
                  cultural={currentHotspot.id === "cultural"}
                  currentHotspotId={currentHotspot.id}
                />
              </HotspotContent>
            )}

            <Menu embassyLevel={false} />
            <RoomsHistory
              history={roomsHistory}
              room={currentRoom}
              previousRoomsClass={previousRoomsClass}
              onClick={this.showRoomsHistoryMap}
            />
            {showRoomHistoryMapLarge && (
              <RoomsHistoryMap
                room={currentRoom}
                hideRoomsHistoryMap={this.hideRoomsHistoryMap}
                previousRoomsClass={previousRoomsClass}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  currentGuide: storeState.currentGuide,
  contentVisible: storeState.contentVisible,
  roomsHistory: storeState.roomsHistory,
  roomsToHighlight: storeState.roomsToHighlight,
  currentHotspot: storeState.currentHotspot,
  typeOfContent: storeState.typeOfContent
});

const mapDispatchToProps = {
  setRoom,
  setHotspot,
  hideContent
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomOverlay);
