import React from "react";
import { ReactComponent as HotspotIcon } from "../../assets/hotspot.svg";
import { isMobile } from "./../../index.js";

export default class HotspotButton extends React.Component {
  state = {
    showTooltip: false
  };
  handleHover = () => {
    !isMobile && this.setState({ showTooltip: true });
  };

  handleHoverEnd = () => {
    this.setState({ showTooltip: false });
  };
  render() {
    let { hotspot, onClick, guide, ...props } = this.props;
    let { showTooltip } = this.state;
    const verticalPosition = hotspot.offset[1] < 15 ? "bottom" : "top";
    const horizontalPosition = hotspot.offset[0] < 10 ? "right" : "left";
    const classes = `HotspotButton Color__${guide.id}--fill ${
      showTooltip
        ? `HotspotButton--ShowTooltip HotspotButton--ShowTooltip__${verticalPosition}`
        : ""
    }`;
    return (
      <React.Fragment>
        <button
          className={classes}
          id={"HotspotButton-" + hotspot.id}
          onClick={onClick}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleHoverEnd}
          aria-label={hotspot.name}
          {...props}
        >
          <HotspotIcon />
          {showTooltip && (
            <div
              className={`TooltipText TooltipText--${verticalPosition} TooltipText--${horizontalPosition}`}
            >
              {hotspot.name}
            </div>
          )}
        </button>
      </React.Fragment>
    );
  }
}
