import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { connect } from "react-redux";
import { showContent, hideContent } from "../../redux/actionCreators";

import { WrappedContent, FadeOutOverlay, HelpContent } from "../";

class VoiceOver extends React.Component {
  render() {
    const {
      currentGuide,
      showContent,
      contentVisible,
      currentHotspot,
      typeOfContent,
      currentHelp,
      showHelp,
      children
    } = this.props;
    const animationSettings = {
      transitionName: "expand",
      transitionAppearTimeout: 1500,
      transitionEnterTimeout: 1500,
      transitionLeaveTimeout: 1500,
      transitionAppear: true,
      transitionEnter: true,
      transitionLeave: true
    };

    const shouldShowHelp = currentHelp && showHelp
    const shouldShowContent = contentVisible && typeOfContent !== "hotspot_content" && !showHelp

    return (
      <div
        className={`VoiceOver ${
          this.props.embassyLevel ? "VoiceOver__embassy" : "VoiceOver__room"
        }`}
      >
        <section className="VoiceOver__image" style={this.props.style}>
          <img
            src={currentGuide.image}
            alt={currentGuide.title}
            onClick={showContent}
          />
        </section>
        <ReactCSSTransitionGroup {...animationSettings}>
          {shouldShowContent && (
            <WrappedContent
              children={children}
              currentHotspot={currentHotspot}
              typeOfContent={typeOfContent}
            />
          )}
          {shouldShowHelp && (
            <WrappedContent
              typeOfContent="help"
              children={<HelpContent />}
            />
          )}
        </ReactCSSTransitionGroup>
        {currentHotspot && <FadeOutOverlay />}
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  currentGuide: storeState.currentGuide,
  contentVisible: storeState.contentVisible,
  currentHotspot: storeState.currentHotspot,
  typeOfContent: storeState.typeOfContent,
  currentHelp: storeState.currentHelp,
  showHelp: storeState.showHelp
});

const mapDispatchToProps = {
  showContent,
  hideContent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoiceOver);
