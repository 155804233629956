import { SET_GUIDE, SET_ROOM, SHOW_EMBASSY, RESET_ROOMS_HISTORY, SHOW_CONTENT, HIDE_CONTENT, SET_HOTSPOT, SHOW_HELP, HIDE_HELP } from "./actionTypes"

export function setGuide(guide) {
  return {
    type: SET_GUIDE,
    payload: guide
  }
}

export function setRoom(room) {
  return {
    type: SET_ROOM,
    payload: room
  }
}

export function showEmbassy() {
  return {
    type: SHOW_EMBASSY
  }
}

export function resetRoomsHistory() {
  return {
    type: RESET_ROOMS_HISTORY
  }
}

export function showContent() {
  return {
    type: SHOW_CONTENT
  }
}

export function hideContent() {
  return {
    type: HIDE_CONTENT
  }
}

export function setHotspot(hotspot) {
  return {
    type: SET_HOTSPOT,
    payload: hotspot
  }
}

export function showHelp(helpId) {
  return {
    type: SHOW_HELP,
    payload: helpId
  }
}

export function hideHelp() {
  return {
    type: HIDE_HELP
  }
}