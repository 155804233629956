import React from "react";

export default class MobileHelpers extends React.Component {
  state = { showSwipeMessage: false, isLandscape: false };
  handleResize = _ => {
    this.setState({
      isLandscape: !!Math.floor(
        Math.min(1, window.innerWidth / window.innerHeight)
      ),
      showSwipeMessage: window.innerHeight < document.body.offsetHeight
    });
  };
  handleTouchMove = e => {
    const { isLandscape, showSwipeMessage } = this.state;
    if (isLandscape && showSwipeMessage) {
      return;
    } else {
      e.preventDefault();
    }
  };
  componentDidMount() {
    this.handleResize();

    // Listen for resize event to detect rotation
    window.onresize = this.handleResize;

    // Prevent scroll bouncing on mobile
    document.addEventListener("touchmove", this.handleTouchMove, {
      passive: false
    });
  }
  render() {
    const { isLandscape, showSwipeMessage } = this.state;
    return (
      <React.Fragment>
        {(!isLandscape || showSwipeMessage) && (
          <div className="MobileHelpers Content">
            <p className="MobileHelpers__message">
              {!isLandscape
                ? "Please turn your device in landscape mode"
                : "Please swipe up to enter full screen"}
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }
}
