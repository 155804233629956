import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";

import reducer from "./reducer";

const initialState = {
  currentGuide: undefined,
  previousGuide: undefined,
  currentRoom: undefined,
  roomsHistory: [],
  contentVisible: true,
  currentHotspot: undefined,
  typeOfContent: undefined,
  roomsToHighlight: undefined,
  currentHelp: undefined,
  showHelp: false
};

let store;
if (process.env.NODE_ENV === `development`) {
  store = createStore(reducer, initialState, applyMiddleware(logger));
} else {
  store = createStore(reducer, initialState);
}

export default store
