import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { connect } from "react-redux";
import { setGuide } from "./redux/actionCreators";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import {
  WelcomeScreen,
  StartScreen,
  EmbassyScreen,
  NotFoundScreen
} from "./screens";

import "./scss/App.scss";

export const addNonBreakingSpace = paragraph => {
  return paragraph.replace(/\s(?=[^\s]*$)/g, "\u00A0");
};

const transitionSettings = {
  transitionName: "fade",
  transitionAppearTimeout: 500,
  transitionAppear: true,
  transitionEnter: false,
  transitionLeave: false
};

class App extends React.Component {
  render() {
    const { currentGuide, previousGuide } = this.props;
    return (
      <main className="App">
        <Router>
          <ReactCSSTransitionGroup {...transitionSettings}>
            <Switch>
              <Route
                exact
                path="/"
                render={props => <WelcomeScreen {...props} />}
              />
              <Route
                path="/start"
                render={props => <StartScreen {...props} />}
              />
              <Route
                path="/embassy"
                render={props =>
                  currentGuide ? (
                    <EmbassyScreen
                      showPreviewAnimation={!previousGuide}
                      {...props}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route render={props => <NotFoundScreen {...props} />} />
            </Switch>
          </ReactCSSTransitionGroup>
        </Router>
      </main>
    );
  }
}

const mapStateToProps = storeState => ({
  currentGuide: storeState.currentGuide,
  previousGuide: storeState.previousGuide,
  currentRoom: storeState.currentRoom,
  roomsHistory: storeState.roomsHistory
});

const mapDispatchToProps = {
  setGuide
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
