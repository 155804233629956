import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "../assets/arrow-left-carousel.svg";
import right from "../assets/arrow-left-carousel.svg";

function Arrow({ onClick, direction }) {
  const arrowImage = { left, right }[direction];
  const settings = {
    className: "Carousel__arrow Carousel__arrow--" + direction,
    onClick
  };
  return (
    <button {...settings}>
      <img src={arrowImage} alt={direction + "arrow"} />
    </button>
  );
}

export default class Carousel extends React.Component {
  state = {
    showPrevArrow: false,
    showNextArrow: true
  };

  setShowPrevArrow = () => {
    let currentSlide = this.slider.innerSlider.state.currentSlide;
    currentSlide > 0
      ? this.setState({ showPrevArrow: true })
      : this.setState({ showPrevArrow: false });
  };

  // currentSlide is position of the left most item on the screen
  // slideCount is total number of items
  // slidesToShow is max number of items shown at once
  setShowNextArrow = () => {
    let slideCount = this.slider.innerSlider.state.slideCount;
    let slidesToShow = this.slider.innerSlider.props.slidesToShow;
    let currentSlide = this.slider.innerSlider.state.currentSlide;
    currentSlide + slidesToShow > slideCount
      ? this.setState({ showNextArrow: false })
      : this.setState({ showNextArrow: true });
  };

  render() {
    const defaultSettings = {
      infinite: false,
      speed: 500,
      slidesToShow: 5.3,
      slidesToScroll: 1,
      centerMode: false,
      prevArrow: this.state.showPrevArrow ? <Arrow direction="left" /> : false,
      nextArrow: this.state.showNextArrow ? <Arrow direction="right" /> : false,
      afterChange: () => {
        this.setShowPrevArrow();
        this.setShowNextArrow();
      },
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 4.3
          }
        },
        {
          breakpoint: 667,
          settings: {
            slidesToShow: 3.3
          }
        }
      ]
    };
    const { children, settings } = this.props;
    const currentSettings = {
      ...defaultSettings,
      ...settings
    };
    return (
      <div className="Carousel">
        <Slider ref={slider => (this.slider = slider)} {...currentSettings}>
          {children.map((item, index) => (
            <div className="Carousel__item" key={"slide-" + index}>
              {item}
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
