import sortBy from "lodash/sortBy";
import find from "lodash/find";
import { rooms } from "../data";

import {
  ENTERING_ROOM,
  CHANGING_GUIDE,
  SWITCHING_ROOM,
  REVIEWING_ADVICE,
  CLICKING_HOTSPOT
} from "../components/HelpContent";
import { embassyIcon, areaIcon, guideIcon, helpIcon } from "../assets";

const createFloorsMenu = () => {
  let floorList = [];

  rooms.forEach(room => {
    let existingFloor = find(floorList, ["id", room.floor]);
    if (!existingFloor) {
      existingFloor = {
        id: room.floor,
        title: room.floor_title,
        floor_order: room.floor_order,
        items: []
      };
      floorList.push(existingFloor);
    }

    existingFloor.items.push(room);
  });

  return sortBy(floorList, "floor_order");
};

const createHelpMenu = helpState => {
  switch (helpState) {
    case "room":
      return [
        {
          id: "help_1",
          helpSection: SWITCHING_ROOM,
          title: "Switching room"
        },
        {
          id: "help_2",
          helpSection: CHANGING_GUIDE,
          title: "Changing guide"
        },
        {
          id: "help_3",
          helpSection: REVIEWING_ADVICE,
          title: "Reviewing your guide's advice"
        },
        {
          id: "help_4",
          helpSection: CLICKING_HOTSPOT,
          title: "Click on a hotspot"
        }
      ];

    case "exterior":
      return [
        {
          id: "help_5",
          helpSection: ENTERING_ROOM,
          title: "Entering a room"
        },
        {
          id: "help_6",
          helpSection: CHANGING_GUIDE,
          title: "Changing guide"
        }
      ];

    default:
      return createHelpMenu("exterior");
  }
};

export default function menu(helpState) {
  return {
    id: "root",
    items: [
      {
        id: "all-rooms",
        alt: "Building",
        title: "Back to main view",
        src: embassyIcon,
        items: null
      },
      {
        id: "floors",
        alt: "Location marker",
        title: "Choose area",
        src: areaIcon,
        items: createFloorsMenu()
      },
      {
        id: "guides",
        alt: "Outline of a person",
        src: guideIcon,
        linkText: "Change guide",
        link: "/start"
      },
      {
        id: "help",
        alt: "Question mark",
        title: "Help",
        src: helpIcon,
        items: createHelpMenu(helpState)
      }
    ]
  };
}
