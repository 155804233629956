import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { connect } from "react-redux";
import {
  setGuide,
  setRoom,
  showEmbassy,
  showHelp
} from "../../redux/actionCreators";

import { Link } from "react-router-dom";
import find from "lodash/find";

import { rooms } from "../../data";
import menu from "../../lib/MenuObject";
import { onDocumentClick } from "../../lib/HelperFunctions";
import { RootMenuFooter } from "../";
import { menuOpen, closeIconWhite } from "../../assets";

const defaultSettings = {
  component: React.Fragment,
  transitionEnterTimeout: 500,
  transitionLeaveTimeout: 500
};

function SubMenu({ children, show, menuClass, level, onToggleMenu }) {
  const settings = { ...defaultSettings, transitionName: menuClass };
  return (
    <ReactCSSTransitionGroup {...settings}>
      {show && (
        <div className={`${menuClass} menu-panel`}>
          {level === 0 && (
            <img
              className="close-menu"
              onClick={() => onToggleMenu(0, "root")}
              src={closeIconWhite}
              alt="Close menu"
            />
          )}
          <ul children={children} />
          {level === 0 && <RootMenuFooter />}
        </div>
      )}
    </ReactCSSTransitionGroup>
  );
}

class ConstructMenuItem extends React.Component {
  state = {
    activeItem: ""
  };
  setObject(item, from, callback) {
    let { onToggleMenu } = this.props;
    let object = find(from, ["id", item.id]);
    if (object) {
      onToggleMenu(0, "root");
      callback(object);
    }
  }

  setActiveOption(itemId) {
    this.setState({
      activeItem: itemId === this.state.activeItem ? "" : itemId
    });
  }

  static getDerivedStateFromProps(props) {
    let { menu, level, visibleMenus } = props;
    if (visibleMenus[level] !== menu.id) {
      return {
        activeItem: ""
      };
    }
    return null;
  }

  onClickHandler(item) {
    let { level, onToggleMenu, setRoom, showEmbassy, showHelp } = this.props;
    this.setActiveOption(item.id);
    if ((item.items || []).length > 0) {
      onToggleMenu(level + 1, item.id); // Show next menu panel if current option has more items
    } else if (item.room) {
      this.setObject(item, rooms, setRoom); //Choose room from last menu panel
    } else if (item.id === "all-rooms") {
      showEmbassy();
      onToggleMenu(0, "root");
    } else if (item.helpSection) {
      showHelp(item.helpSection);
      onToggleMenu(0, "root");
    } else {
      onToggleMenu(0, "root");
    }
  }

  render() {
    let { menu, level, visibleMenus, onResetGuide, ...funcs } = this.props;
    let { activeItem } = this.state;
    return (menu.items || []).length === 0 ? null : (
      <React.Fragment key={menu.id}>
        <SubMenu
          show={visibleMenus[level] === menu.id}
          level={level}
          menuClass={`menu-${level}`}
          onToggleMenu={this.props.onToggleMenu}
        >
          {menu.items.map(item => (
            <li
              key={item.id}
              className={
                activeItem === item.id ? `ActiveMenuItem__${level}` : ""
              }
              style={{ position: "relative" }}
              tabIndex={0}
              onClick={() => this.onClickHandler(item)}
              onKeyDown={e => e.keyCode === 13 && this.onClickHandler(item)}
            >
              {item.src && <img src={item.src} alt={item.alt} />}
              {item.href ? (
                <a title={item.title} href={item.href}>
                  {item.title}
                </a>
              ) : (
                item.title
              )}
              {item.link && (
                <span>
                  <Link
                    onClick={onResetGuide}
                    to={item.link}
                    aria-label={item.ariaLabel}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: "0",
                      right: "0"
                    }}
                  />
                  {item.linkText}
                </span>
              )}
            </li>
          ))}
        </SubMenu>
        {menu.items.map(item => (
          <ConstructMenuItem
            key={item.id}
            menu={item}
            level={level + 1}
            visibleMenus={visibleMenus}
            {...funcs}
          />
        ))}
      </React.Fragment>
    );
  }
}

class Menu extends React.Component {
  state = {
    visibleMenus: []
  };

  expandMenu = (level, id) => {
    var menus = this.state.visibleMenus;
    menus[level] = menus[level] === id ? null : id;

    let visibleMenus = menus.map((menu, index) => {
      return index > level ? null : menu;
    });

    this.setState({ visibleMenus });
  };

  isMenuOpen = () => this.state.visibleMenus[0] != null;

  onDocumentClickMenu = e => {
    onDocumentClick(e, ["menu-panel", "toggle-menu"], () => {
      this.setState({
        visibleMenus: []
      });
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.onDocumentClickMenu);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClickMenu);
  }

  render() {
    const { setGuide, setRoom, showEmbassy, showHelp } = this.props;
    return (
      <React.Fragment>
        <ConstructMenuItem
          menu={menu(this.props.currentRoom ? "room" : "exterior")}
          level={0}
          visibleMenus={this.state.visibleMenus}
          onToggleMenu={(level, id) => this.expandMenu(level, id)}
          onResetGuide={() => setGuide(null)}
          setRoom={setRoom}
          showEmbassy={showEmbassy}
          showHelp={showHelp}
        />
        <img
          className={`toggle-menu ${
            this.props.embassyLevel
              ? "toggle-menu--embassy"
              : "toggle-menu--room"
          }`}
          onClick={() => this.expandMenu(0, "root")}
          src={menuOpen}
          alt="Open menu"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = storeState => ({
  currentRoom: storeState.currentRoom
});

const mapDispatchToProps = {
  setGuide,
  setRoom,
  showEmbassy,
  showHelp
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
