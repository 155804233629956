import React from "react";
import { Content } from "../";
import { closeIcon } from "../../assets";

import { connect } from "react-redux";
import { hideContent, hideHelp } from "../../redux/actionCreators";
import { onDocumentClick } from "../../lib/HelperFunctions";

class WrappedContent extends React.Component {
  onDocumentClickMap = e => {
    let { hideContent, typeOfContent, hideHelp } = this.props;
    onDocumentClick(
      e,
      ["VoiceOver__content", "Embassy__Room__Controls"],
      () => {
        typeOfContent === "help" ? hideHelp() : hideContent();
      }
    );
  };

  componentDidMount() {
    document.addEventListener("click", this.onDocumentClickMap);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClickMap);
  }
  render() {
    let {
      hideContent,
      currentGuide,
      currentHotspot,
      children,
      typeOfContent,
      hideHelp
    } = this.props;

    return (
      <Content
        className={`Color__${
          currentGuide.id
        } VoiceOver__content VoiceOver__content--${typeOfContent} ${
          currentHotspot ? "Hotspot__content" : ""
        }`}
      >
        <button
          className="VoiceOver__close"
          onClick={typeOfContent === "help" ? hideHelp : hideContent}
        >
          <img src={closeIcon} alt="Close pop up" />
        </button>
        {children}
      </Content>
    );
  }
}

const mapStateToProps = storeState => ({
  currentGuide: storeState.currentGuide
});

const mapDispatchToProps = {
  hideContent,
  hideHelp
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedContent);
