import React from "react";
import { ReactComponent as HistoryMap } from "../../assets/map.svg";

export default function RoomsHistory({ previousRoomsClass, room, onClick }) {
  return (
    <div className="RoomsHistory">
      <HistoryMap
        className={`HistoryMap--${room.id} ${previousRoomsClass}`}
        onClick={onClick}
      />
    </div>
  );
}
