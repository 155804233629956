import React from "react";
import hotspotArrow from "../../assets/hotspot-arrow.png";

export default function HotspotInformationNav({
  totalSlides,
  currentSlide,
  onNextClick,
  onPrevClick,
  onDotClick
}) {
  let progressDots = [];
  if (totalSlides > 1) {
    progressDots = [...Array(totalSlides)].map((_, i) => {
      return (
        <div
          key={`progress-dot-${i}`}
          className={`ProgressDot ProgressDot--${
            i === currentSlide ? "active" : "inactive"
          }`}
          aria-label="Select slide"
          onClick={() => onDotClick(i)}
        />
      );
    });
  }

  const nextButtonVisible = currentSlide < totalSlides - 1;
  const prevButtonVisible = currentSlide > 0;

  return (
    <div className="HotspotContent__Nav">
      <div
        style={{
          visibility: prevButtonVisible ? "visible" : "hidden"
        }}
        className="Button--Prev"
        aria-label="Previous slide"
        onClick={onPrevClick}
      >
        <img src={hotspotArrow} alt="Previous Slide" />
      </div>
      {progressDots}
      <div
        style={{
          visibility: nextButtonVisible ? "visible" : "hidden"
        }}
        className="Button--Next"
        aria-label="Next slide"
        onClick={onNextClick}
      >
        <img src={hotspotArrow} alt="Next Slide" />
      </div>
    </div>
  );
}
