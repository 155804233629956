import React from 'react'
import { connect } from 'react-redux'

import { menuOpen } from "../assets"
import { ReactComponent as FocusPlusIcon } from "../assets/focus-plus.svg"
import { ReactComponent as HotspotIcon } from "../assets/hotspot.svg"

const ENTERING_ROOM = "entering_room"
const CHANGING_GUIDE = "changing_guide"
const SWITCHING_ROOM = "switching_room"
const REVIEWING_ADVICE = "reviewing_advice"
const CLICKING_HOTSPOT = "clicking_hotspot"

const HelpContent = ({ currentHelp, currentGuide }) => {
  switch (currentHelp) {
    case ENTERING_ROOM:
      return (
        <React.Fragment>
          <h3>Entering a room</h3>
          <p>To enter a room, mouse over a <FocusPlusIcon className={`HotspotIcon-example Color__${currentGuide.id}--fill`} /> hotspot. If the thumbnail looks like a room you’d like to see, click enter to go there. You can also see the full room list under ‘Choose area’ in the main menu <img className="HotspotIcon-example" src={menuOpen} alt="Menu Icon" />.</p>
        </React.Fragment>
      );

    case CHANGING_GUIDE:
      return (
        <React.Fragment>
          <h3>Changing guide</h3>
          <p>If you’d like to change guides and find out about another part of our work, just select ‘Change guide’ from the menu <img className="HotspotIcon-example" src={menuOpen} alt="Menu Icon" />. Then, you’ll choose whether to restart the embassy experience, or continue where you are, with a new guide.</p>
        </React.Fragment>
      );

    case SWITCHING_ROOM:
      return (
        <React.Fragment>
          <h3>Switching room</h3>
          <p>You can change areas in a variety of ways:</p>
          <ul>
            <li>Click the mini-map in the bottom-left corner and select an area</li>
            <li>Click ‘Choose area’ in the menu <img className="HotspotIcon-example" src={menuOpen} alt="Menu Icon" /></li>
            <li>Use the arrows around the frame to move between adjacent areas and rooms</li>
            <li>Click ‘Back to main view’ in the menu to see the whole embassy and choose a new room</li>
          </ul>
        </React.Fragment>
      );
    
    case REVIEWING_ADVICE:
      return (
        <React.Fragment>
          <h3>Review your guide’s advice</h3>
          <p>If you’d like to see what your guide last said, just click on their face in the top left corner to bring the window back up.</p>
        </React.Fragment>
      );

    case CLICKING_HOTSPOT:
      return (
        <React.Fragment>
          <h3>Click on a hotspot</h3>
          <p>The main hotspots <HotspotIcon className={`HotspotIcon-example Color__${currentGuide.id}--fill`} /> will tell you more about the work of your guide’s group. The cultural hotspots <HotspotIcon className="HotspotIcon-example Color__cultural--fill" /> will tell you key facts about us. Click or tap on either one to bring up more information</p>
        </React.Fragment>
      );

    default:
      return (<p>Content to follow</p>)
  }
}

const mapStateToProps = ({ currentHelp, currentGuide }) => {
  return {
    currentHelp,
    currentGuide
  }
}
export { ENTERING_ROOM, CHANGING_GUIDE, SWITCHING_ROOM, REVIEWING_ADVICE, CLICKING_HOTSPOT }
export default connect(mapStateToProps)(HelpContent);