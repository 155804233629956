import React from "react";
import TimelineMax from "gsap/TimelineMax";
import { Linear } from "gsap/TweenMax";

import { Button, Content } from "../components";
import logoImage from "../assets/fcdo-services-logo.svg";
import { ReactComponent as Truck } from "../assets/truck.svg";
import embassyLanding from "../assets/embassy-landing.png";
import cloudOne from "../assets/cloud_1.png";
import cloudTwo from "../assets/cloud_2.png";
import cloudThree from "../assets/cloud_3.png";
import Analytics from "../lib/Analytics";

function animateTruck(truck) {
  let wheels = document.querySelectorAll("[id*='wheel']");
  let wheelAnimation = new TimelineMax({ repeat: -1, paused: true });

  wheelAnimation.to(wheels, 1, {
    rotation: 360,
    transformOrigin: "50% 50%",
    ease: Linear.easeNone
  });
  var drive = new TimelineMax({
    onStart: () => wheelAnimation.play(),
    onComplete: () => wheelAnimation.stop()
  });

  drive.to(truck, 5, { left: "77vw", ease: Linear.easeNone });
}

function animateClouds(...clouds) {
  let timeline = new TimelineMax({});
  timeline
    .to(clouds[0], 50, { x: "+=130vw", ease: Linear.easeNone })
    .to(clouds[1], 70, { x: "+=130vw", ease: Linear.easeNone }, "+2")
    .to(clouds[2], 90, { x: "+=130vw", ease: Linear.easeNone }, "+5")
    .to(clouds[3], 100, { x: "+=130vw", ease: Linear.easeNone }, "+0.5");
}

const TruckComponent = React.forwardRef((props, ref) => {
  return (
    <div className="Welcome__truck" ref={ref}>
      <Truck />
    </div>
  );
});

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.truckRef = React.createRef();
    this.cloudOneRef = React.createRef();
    this.cloudTwoRef = React.createRef();
    this.cloudThreeRef = React.createRef();
    this.cloudFourRef = React.createRef();
    this.Tracker = new Analytics();
  }

  componentDidMount() {
    this.Tracker.pageView("/embassy", "Virtual Embassy");
    animateTruck(this.truckRef.current);
    animateClouds(
      this.cloudOneRef.current,
      this.cloudTwoRef.current,
      this.cloudThreeRef.current,
      this.cloudFourRef.current
    );
  }

  render() {
    return (
      <article className="Welcome">
        <div className="Welcome__background">
          <img
            src={embassyLanding}
            alt="Embassy Exterior"
            className="Welcome__background-scene"
          />
          <img
            src={cloudOne}
            alt="Cloud"
            className="Cloud__One"
            ref={this.cloudOneRef}
          />
          <img
            src={cloudTwo}
            alt="Cloud"
            className="Cloud__Two"
            ref={this.cloudTwoRef}
          />
          <img
            src={cloudThree}
            alt="Cloud"
            className="Cloud__Three"
            ref={this.cloudThreeRef}
          />
          <img
            src={cloudThree}
            alt="Cloud"
            className="Cloud__Four"
            ref={this.cloudFourRef}
          />

          <TruckComponent ref={this.truckRef} />
        </div>
        <Content className="Welcome__content">
          <img
            src={logoImage}
            alt="FCDO Services Logo"
            className="Welcome__logo"
          />

          <h1>Welcome to the virtual embassy</h1>
          <p>
            Want to see how some of our teams support embassies worldwide? From
            engineering specialists to logistics and project management experts,
            choose your embassy guide to get a snapshot of just some of what we
            do and see where your skills could fit. There’s even a route for
            apprentices, to see your future here.
          </p>
          <Button onClick={() => this.props.history.push("/start")}>
            Enter now
          </Button>
        </Content>
      </article>
    );
  }
}

export default Welcome;
