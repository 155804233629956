import React from "react";
import { Button, Content } from "../components";
import logoImage from "../assets/logo.svg";

export default function NotFound({ history }) {
  return (
    <article className="Welcome">
      <Content className="Welcome__content">
        <img
          src={logoImage}
          alt="FCDO Services Logo"
          className="Welcome__logo"
        />
        <h1>Page not found</h1>
        <p>Oh dear. We couldn't find that page.</p>
        <Button onClick={() => history.replace("/")}>Start over</Button>
      </Content>
    </article>
  );
}
