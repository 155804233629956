import React from "react";
import { Button, LogoWhite } from "../";
import Analytics from "../../lib/Analytics";

export default function RootMenuFooter({ className, type, ...otherProps }) {
  const onVisitCareersSite = e => {
    const Tracker = new Analytics();
    Tracker.event("Careers Site Button", "click", e.target.title);
  };
  return (
    <div id="menu-footer">
      <Button className="Button--green">
        <a
          title="Go to careers site"
          href="https://www.fcdoservicescareers.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={onVisitCareersSite}
        >
          Go to careers site
        </a>
      </Button>
      <LogoWhite className="Menu--Logo" />
    </div>
  );
}
